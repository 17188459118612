<template>
  <div class="container">
    <Progress
      :active-index="1"
      :step-list="stepList"
    />
    <div class="main">
      <Description
        class="description"
        title="项目信息"
        :field-name-map="fieldNameMap.project"
        :data-source="projectData"
        :time-field-list="projectDataTimeFieldList"
        :amount-field-list="projectDataAmountFieldList"
      />
      <Description
        class="description"
        title="投标人信息"
        :field-name-map="fieldNameMap.bidder"
        :data-source="projectData"
      />
      <Description
        class="description"
        title="担保公司信息"
        :field-name-map="fieldNameMap.guaranteeCompany"
        :data-source="projectData"
      />
      <InvitationLetter
        class="invitationLetter"
        @change="handleKnowChange"
      />
      <Summary
        class="summary"
        :total-amount="10000"
      />
      <Operate class="operate" />
    </div>
  </div>
</template>

<script>
import Description from '@/components/Description';
import Progress from '@/components/Progress';
import Operate from './components/Operate';
import Summary from './components/Summary';
import InvitationLetter from './components/InvitationLetter';
import fieldNameMap from './fieldNameMap';

export default {
  name: 'InsureConfirmPage',
  components: {
    Progress,
    Operate,
    Summary,
    Description,
    InvitationLetter,
  },
  data() {
    return {
      isKnow: false,
      fieldNameMap,
      stepList: [ '填写投保信息', '确认投保信息', '提交投保完成' ],
      projectData: {
        id: 'fugiat ut laborum nisi',
        orderNo: 100000,
        projectName: 'occaecat incididunt Duis cupidatat',
        createTime: 1619337095774,
        amount: 100000,
      },
      projectDataTimeFieldList: [ 'createTime' ],
      projectDataAmountFieldList: [ 'amount' ],
      bidderData: {},
      guaranteeCompanyData: {},
    };
  },
  methods: {
    handleKnowChange(value) {
      this.isKnow = value;
    },
  },
};
</script>
<style lang="less" scoped>
.container{
  background-color: #fff;
  min-height: 600px;
}
.main{
  padding: 30px 50px 95px;
}
.operate{
  margin-top:80px;
}
.description+.description{
  margin-top: 50px;
}
.invitationLetter{
  margin-top: 50px;
}
.summary{
  margin-top: 30px;
}
</style>
